import {createRouter, createWebHashHistory} from "vue-router"

import Login from '@/components/Login'
import Main from '@/components/Main'
import QnaDetail from '@/components/support/Qna/QnaDetail'
import QnaList from '@/components/support/Qna/QnaList'
import SiteInfo from "@/components/setting/SiteInfo"
import ManagerList from "@/components/setting/ManagerList";
import ManagerDetail from "@/components/setting/ManagerDetail";
import PartnerList from "@/components/setting/PartnerList";

export default createRouter({
    history: createWebHashHistory(),//2.x대를 설치하시면 작동을 안합니다.
    scrollBehavior (){
        return { x: 0, y: 0 }
    },
    routes: [
        {
            path: '/',
            name: 'Login',
            component: Login,
            meta: {
                hideLNBHeader: true
            },
        },
        {
            path: '/main',
            name: 'Main',
            component: Main,
        },
        {
            path: '/support/qna',
            name: 'QnaList',
            component: QnaList,
        },
        {
            path: '/support/qna/detail',
            name: 'QnaDetail',
            component: QnaDetail,
        },
        {
            path: '/setting/partner/list',
            name: 'PartnerList',
            component: PartnerList,
        },
        {
            path: '/setting/manager/list',
            name: 'ManagerList',
            component: ManagerList,
        },
        {
            path: '/setting/manager/detail',
            name: 'ManagerDetail',
            component: ManagerDetail,
        },
        {
            path: '/setting/siteinfo',
            name: 'SiteInfo',
            component: SiteInfo,
        },
    ],
})