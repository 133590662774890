<template>
  <div>
    <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-4">
          <div class="col-sm-12">
            <h4 class="m-0">물류 배차 담당자 관리</h4>
            <span>물류 배차 담당자 정보를 관리합니다.</span>
          </div><!-- /.col -->
        </div><!-- /.row -->

        <div class="row mb-2">
          <div class="col-sm-12">
            <div class="row">
              <div class="ml-auto">
                <button class="btn btn-success mr-1" @click="goRegister()">담당자 등록</button>
              </div>
            </div>
          </div><!-- /.col -->
        </div><!-- /.row -->

      </div><!-- /.container-fluid -->
    </div>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header">
                <h5 class="card-title">물류 배차 담당자 목록</h5>
              </div><!-- /.card-header -->
              <div class="card-body">
                <table class="table table-bordered" id="excelData">
                  <thead>
                  <tr>
                    <th>No</th>
                    <th>이름</th>
                    <th>직책</th>
                    <th>휴대폰 번호</th>
                    <th>사무실 번호</th>
                    <th>노출</th>
                    <th>상세</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(item, index) in items" :key="index">
                    <td>{{ totalCount - ((page - 1) * size) - index }}</td>
                    <td>{{ item.name }}</td>
                    <td>{{ item.position }}</td>
                    <td>{{ item.phone }}</td>
                    <td>{{ item.tel }}</td>
                    <td class="position-relative">
                      <input class="position-absolute" type="checkbox" :checked="item.deleted === 0">
                      <div class="btn-check" @click="useManager(index)"></div>
                    </td>
                    <td class="text-center">
                      <span class="badge btn btn-outline-warning" @click="goDetail(item.id)">상세보기</span>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div><!-- ./card-body -->
              <div class="card-footer clearfix">
                <pagination ref="Pagination"></pagination>
              </div>
            </div><!-- /.card -->
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div>
    </section>
    <div class="modal fade" id="managerAddModal" tabindex="-1" aria-labelledby="managerAddModalLabel"
         aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="managerAddModalLabel">담당자 등록</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p class="text-danger">* 상세이미지는 최대 5개까지 등록 가능합니다.</p>
            <div class="input-group pb-1">
              <input type="text" class="form-control" v-model.trim="addName" placeholder="*이름">
            </div>
            <div class="input-group pb-1">
              <input type="text" class="form-control" v-model.trim="addPosition" placeholder="*직책">
            </div>
            <div class="input-group pb-1">
              <input type="text" class="form-control" v-model.trim="addPhone" placeholder="*휴대폰번호">
            </div>
            <div class="input-group pb-1">
              <input type="text" class="form-control" v-model.trim="addTel" placeholder="사무실번호">
            </div>
            <div class="input-group pb-1">
              <div class="custom-file">
                <input ref="thumbNailUploader" id="thumbNailFile" type="file" class="custom-file-input" accept="image/*"
                       @change="upload($event, 0)"/>
                <label v-if="addThumbnailImg === null" class="custom-file-label" for="thumbNailFile" style="color: #999;">*썸네일이미지</label>
                <label v-else class="custom-file-label" for="thumbNailFile">{{ addThumbnailName }}</label>
              </div>
            </div>
            <div class="input-group pb-1">
              <div class="custom-file">
                <input ref="detailImgUploader" id="detailImgFile" type="file" class="custom-file-input" accept="image/*"
                       @change="upload($event, 1)" multiple/>
                <label v-if="addDetailImg.length === 0" class="custom-file-label" for="detailImgFile" style="color: #999;">상세이미지</label>
                <label v-else class="custom-file-label" for="detailImgFile">{{ addDetailImg[0].name }}
                  {{ addDetailImg.length > 1 ? ' 외 ' + (addDetailImg.length - 1) + '장' : '' }}</label>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">취소</button>
            <input type="button" class="btn btn-info" value="등록" @click="register()"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from "@/components/common/Pagination"

export default {
  name: "ManagerList",
  path: '/setting/manager/list',
  components: {
    Pagination,
  },
  data() {
    return {
      items: [],
      page: 1,
      totalCount: 0, // 총 데이터 list length
      size: 10, // 한 페이지에 보여줄 size

      /*등록*/
      addThumbnailImg: null,
      addThumbnailName: '',
      addDetailImg: [],
      addName: '',
      addPosition: '',
      addTel: '',
      addPhone: '',
    }
  },
  created() {
    this.$root.$emit(this.$EVENT_LNB_CHANGE, 'setting', 'manager')
  },
  mounted() {
    if (this.$route.query.page) {
      this.page = this.$route.query.page
    }
    if (this.$route.query.size) {
      this.size = this.$route.query.size
    }

    this.getList(this.page)
  },
  methods: {
    getList(_page) {
      let query = ''

      this.$get(this.$SETTING_MANAGER + '?page=' + _page + '&perPage=' + this.size + query, this.$options.name + '_getList', (result) => {
        this.items = result.data.data
        this.totalCount = result.data.total

        this.page = _page

        /* 페이지네이션 */
        try {
          this.$refs.Pagination.init(this.$options.name, null, {}, this.page, this.totalCount, Number(this.size))

        } catch (e) {
          console.log(e)
        }

      }, (result) => {
        this.httpError(result)
      })
    },
    goRegister() {
      /*등록*/
      this.addThumbnailImg = null
      this.addThumbnailName = ''
      this.addDetailImg = []
      this.addName = ''
      this.addPosition = ''
      this.addTel = ''
      this.addPhone = ''

      window.$('#managerAddModal').modal('show');
    },
    register() {
      if (this.addName === '') {
        this.notify('error', '이름을 입력해주세요.')
        return
      }

      if (this.addPosition === '') {
        this.notify('error', '직책을 입력해주세요.')
        return
      }

      if (this.addPhone === '') {
        this.notify('error', '휴대폰번호를 입력해주세요.')
        return
      }

      if (this.addThumbnailImg === null) {
        this.notify('error', '썸네일이미지를 선택해주세요.')
        return
      }

      let formData = new FormData()
      formData.append('thumbnailImg', this.addThumbnailImg)
      formData.append('name', this.addName)
      formData.append('phone', this.addPhone)
      formData.append('position', this.addPosition)

      if (this.addDetailImg.length > 0) {
        for (let i = 0; i < this.addDetailImg.length; i++) {
          formData.append('detailImg[]', this.addDetailImg[i].file)
        }

        // formData.append('detailImg[0]', detailImgFiles[0])
      }

      if (this.addTel !== '') {
        formData.append('tel', this.addTel)
      }

      this.$post(this.$SETTING_MANAGER_REGISTER, this.$options.name + '_register', formData, () => {
        this.notify('success', '성공적으로 등록되었습니다.')
        window.$('#managerAddModal').modal('hide');
        this.getList(1)

      }, (result) => {
        this.httpError(result)
      })
    },
    upload(e, _type) {
      let files = e.target.files || e.dataTransfer.files
      if (!files.length) {
        return
      }

      if (_type === 0) { /*썸네일 이미지*/
        this.addThumbnailImg = files[0]
        this.addThumbnailName = files[0].name

        this.$refs.thumbNailUploader.value = ''

      } else { /*상세 이미지*/

        if (files.length > 5) {
          this.notify('error', '상세이미지는 5장까지 등록 가능합니다')
          return
        }

        this.addDetailImg = []
        for (let i = 0; i < files.length; i++) {
          this.addDetailImg.push({'file': files[i], 'name': files[i].name})
        }

        this.$refs.detailImgUploader.value = ''
      }
    },
    goDetail(_id) {
      this.$router.push({name: 'ManagerDetail', query: {id: _id}})
    },
    useManager(_idx) {
      /* 0:사용, 1:미사용 */
      let item = this.items[_idx]
      let deleted = item.deleted
      if (deleted === 0) {
        deleted = 1
      } else {
        deleted = 0
      }

      this.$delete(this.$SETTING_MANAGER_DELETE + item.id + '?deleted=' + deleted, this.$options.name + '_useManager', () => {
        this.notify('success', '성공적으로 변경되었습니다.')
        this.items[_idx].deleted = deleted

      }, (result) => {
        this.httpError(result)
      })
    }
  },
  watch: {
    '$route.query.page'() {
      if (this.$route.query.page) {
        this.getList(this.$route.query.page)
      } else {
        this.getList(1)
      }
    },
  },
}
</script>

<style scoped>

</style>