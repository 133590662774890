<template>
  <div>
    <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h5 class="m-0">{{ today }}</h5>
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header">
                <h5 class="card-title d-flex">
                  신규 문의
                  <span class="badge btn btn-success ml-3" @click="$router.push({name:'QnaList'})">더보기</span>
                </h5>
              </div><!-- /.card-header -->
              <div class="card-body">
                <table class="table table-bordered" id="excelData">
                  <thead>
                  <tr>
                    <th>등록일</th>
                    <th style="width: auto;">제목</th>
                    <th>작성자</th>
                    <th>상태</th>
                    <th>상세정보</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(qna, index) in qnaList" :key="index">
                    <td>{{ qna.created_at }}</td>
                    <td>{{ qna.title }}</td>
                    <td>{{ qna.name }}</td>
                    <td>{{ qnaState(qna.state) }}</td>
                    <td class="text-center">
                      <span class="badge btn btn-outline-warning" @click="goQnaDetail(qna.id)">상세보기</span>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div><!-- ./card-body -->
            </div><!-- /.card -->
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "Main",
  path: "/main",
  data() {
    return {
      size: 5,
      today: '',
      qnaList: [],
    }
  },
  created() {
    this.emitter.emit(this.$EVENT_LNB_CHANGE, null, null)
  },
  mounted() {
    this.setDate()
    this.getList()
  },
  methods: {
    setDate(){
      let current = new Date()
      let year = current.getFullYear()
      let month = current.getMonth() + 1
      if (month < 10){
        month = '0' + month
      }
      let day = current.getDate()
      if (day < 10){
        day = '0' + day
      }

      this.today = year + '-' + month + '-' + day
    },
    getList() {
      this.$get(this.$SUPPORT_QNA + '?perPage=' + this.size + '&page=1' + '&state=0', this.$options.name + '_getList', (result) => {
        this.qnaList = result.data.data

      }, (result) => {
        this.httpError(result)
      })
    },
    goQnaDetail(_id) {
      this.$router.push({name: 'QnaDetail', query: {id: _id}})
    }
  },
}
</script>

<style scoped>

</style>