export default Object.freeze({
    install(Vue) {
        // 토스트 스타일 알림
        Vue.config.globalProperties.notify = function (type, msg, is_alert = false) {
            if (is_alert) {
                alert(msg)
            } else {
                let originMsg = window.$('#alert-wrap div:first-child span').text()

                if (originMsg !== msg) {
                    if (type === 'error') {
                        window.$('#alert-wrap').append("<div class=\"alert-content bg-red\"><span>" + msg + "</span></div>")
                    } else {
                        window.$('#alert-wrap').append("<div class=\"alert-content\"><span>" + msg + "</span></div>")
                    }
                    setTimeout(function () {
                        window.$('#alert-wrap div:first-child').remove()
                    }, 3000)
                }
            }
        }

        // 기본 HTTP 에러 처리
        Vue.config.globalProperties.httpError = function (data) {
            if (data.status === 401) {
                this.notify('error', data.data.msg, false)

                this.$router.push({name: 'Login'}).catch(() => {
                })
            } else {
                if (data.data.msg !== '') {
                    this.notify('error', data.data.msg, false)
                }
            }
        }

        Vue.config.globalProperties.doBack = function (back, name = 'Dashboard', redirect = false) {
            if (typeof back !== 'undefined') {
                this.$router.back()
            } else {
                if (redirect) {
                    this.$router.push({name: name})
                } else {
                    this.$router.replace({name: name})
                }
            }
        }

        Vue.config.globalProperties.getFormatData = function (data, result) {
            for (const key of Object.keys(data)) {
                if (typeof result.data[key] === 'number') {
                    data[key] = result.data[key].toString()
                } else if (typeof result.data[key] === 'boolean') {
                    data[key] = result.data[key] ? true : false
                } else if (typeof result.data[key] === 'undefined') {
                    data[key] = ''
                } else if (result.data[key] === null) {
                    data[key] = ''
                } else {
                    data[key] = result.data[key]
                }
            }
        }

        // Vue.config.globalProperties.calendarOptions = function () {
        //     let locale = navigator.language || navigator.userLanguage;
        //     return {
        //         format: 'YYYY-MM-DD',
        //         locale: moment.locale(`${locale}`),
        //         icons: {
        //             // time: "fa fa-clock",
        //             // date: "fa fa-calendar",
        //             // up: "fa fa-arrow-up",
        //             // down: "fa fa-arrow-down",
        //             previous: "fa fa-chevron-left",
        //             next: "fa fa-chevron-right",
        //             // today: "fa fa-clock-o",
        //             // clear: "fa fa-trash-o",
        //             // close: 'fa fa-close',
        //         }
        //     }
        //
        // }
    }
})
