<template>
  <div>
    <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-4">
          <div class="col-sm-12">
            <h4 class="m-0">주요 거래처 관리</h4>
            <span>주요 거래처 정보를 관리합니다.</span>
          </div><!-- /.col -->
        </div><!-- /.row -->

        <div class="row mb-2">
          <div class="col-sm-12">
            <div class="row">
              <div class="ml-auto">
                <button class="btn btn-success mr-1" @click="goRegister()">거래처 등록</button>
              </div>
            </div>
          </div><!-- /.col -->
        </div><!-- /.row -->

        <div class="row mb-2">
          <div class="col-sm-12">
            <h5 class="mb-2">필터</h5>
            <div class="row">
              <input type="text" class="form-control w-auto mr-1" placeholder="거래처 이름 검색" v-model.trim="searchWord">
              <select class="form-control w-auto mr-1" v-model="size">
                <option value="10">10줄</option>
                <option value="20">20줄</option>
                <option value="50">50줄</option>
                <option value="100">100줄</option>
              </select>
              <button class="btn btn-primary" @click="goSearch()">검색</button>
            </div>
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header">
                <h5 class="card-title">거래처 목록</h5>
              </div><!-- /.card-header -->
              <div class="card-body">
                <table class="table table-bordered" id="excelData">
                  <thead>
                  <tr>
                    <th>No</th>
                    <th>이름</th>
                    <th>이미지</th>
                    <th>생성일</th>
                    <th>수정</th>
                    <th>삭제</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(item, index) in items" :key="index">
                    <td>{{ totalCount - ((page - 1) * size) - index }}</td>
                    <td>{{ item.name }}</td>
                    <td>{{ item.path }}</td>
                    <td>{{ item.created_at }}</td>
                    <td class="text-center">
                      <span class="badge btn btn-outline-warning" @click="goEdit(item)">수정하기</span>
                    </td>
                    <td class="text-center">
                      <span class="badge btn btn-outline-danger" @click="goDelete(item)">삭제하기</span>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div><!-- ./card-body -->
              <div class="card-footer clearfix">
                <pagination ref="Pagination"></pagination>
              </div>
            </div><!-- /.card -->
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div>
    </section>
    <div class="modal fade" id="partnerAddModal" tabindex="-1" aria-labelledby="partnerAddModalLabel"
         aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="partnerAddModalLabel">거래처 등록</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="input-group pb-1">
              <input type="text" class="form-control" v-model.trim="addName" placeholder="*거래처 이름">
            </div>
            <div class="input-group pb-1">
              <div class="custom-file">
                <input ref="addPartnerUploader" id="addPartnerFile" type="file" class="custom-file-input"
                       accept="image/*"
                       @change="upload($event, 0)"/>
                <label v-if="addImg === null" class="custom-file-label" for="addPartnerFile" style="color: #999;">*거래처
                  이미지(276*90)</label>
                <label v-else class="custom-file-label" for="addPartnerFile">{{ addImgName }}</label>
              </div>
            </div>
            <div v-if="addImg !== null">
              <img :src="addImgUrl" alt="거래처 로고 이미지" class="img-thumbnail partner-logo">
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">취소</button>
            <input type="button" class="btn btn-info" value="등록" @click="register()"/>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="partnerEditModal" tabindex="-1" aria-labelledby="partnerEditModalLabel"
         aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="partnerEditModalLabel">거래처 정보 수정</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p class="text-danger">* 수정하실 항목을 변경해주세요</p>
            <div class="input-group pb-1">
              <div class="input-group-prepend">
                <span class="input-group-text">*거래처 이름</span>
              </div>
              <input type="text" class="form-control" v-model.trim="editName">
            </div>
            <div class="input-group pb-1">
              <div class="input-group-prepend">
                <span class="input-group-text">*거래처 이미지(276*90)</span>
              </div>
              <div class="custom-file">
                <input ref="editPartnerUploader" id="editPartnerFile" type="file" class="custom-file-input"
                       accept="image/*"
                       @change="upload($event, 1)"/>
                <label class="custom-file-label" for="editPartnerFile">{{ editImgName }}</label>
              </div>
            </div>
            <div v-if="editImg !== null">
              <img :src="editImgUrl" alt="거래처 로고 이미지" class="img-thumbnail partner-logo">
            </div>
            <div v-else>
              <img :src="$IMAGE_DOMAIN + editImgName" alt="거래처 로고 이미지" class="img-thumbnail partner-logo">
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">취소</button>
            <input type="button" class="btn btn-info" value="저장" @click="edit()"/>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="partnerDeleteModal" tabindex="-1" aria-labelledby="partnerDeleteModalLabel"
         aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="partnerDeleteModalLabel">거래처 정보 삭제 확인</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p class="text-dark"><strong class="text-danger">{{ deleteItem.name }}</strong>를 삭제하시겠습니까?<br>삭제 후엔 복구가 불가합니다.</p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">취소</button>
            <input type="button" class="btn btn-info" value="삭제" @click="del()"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from "@/components/common/Pagination"

export default {
  name: "PartnerList",
  path: '/partner/list',
  components: {
    Pagination,
  },
  data() {
    return {
      items: [],
      page: 1,
      totalCount: 0, // 총 데이터 list length
      size: 10, // 한 페이지에 보여줄 size
      searchWord: '',

      /*등록*/
      addName: '',
      addImg: null,
      addImgName: '',
      addImgUrl: '',

      /*수정*/
      id: 0,
      editName: '',
      editImg: null,
      editImgName: '',
      editImgUrl: '',

      /*삭제*/
      deleteItem: '',
    }
  },
  created() {
    this.$root.$emit(this.$EVENT_LNB_CHANGE, 'setting', 'partner')
  },
  mounted() {
    if (this.$route.query.page) {
      this.page = this.$route.query.page
    }
    if (this.$route.query.size) {
      this.size = this.$route.query.size
    }
    if (this.$route.query.searchWord) {
      this.searchWord = this.$route.query.searchWord
    }

    this.getList(this.page)
  },
  methods: {
    goSearch() {
      let query = {
        page: 1 + '',
        size: this.size + ''
      }

      if (this.searchWord !== '') {
        query.searchWord = this.searchWord
      }

      this.$router.replace({name: this.$options.name, query: query})
      this.getList(1)
    },
    getList(_page) {
      let queryString = ''

      if (this.searchWord !== '') {
        queryString = queryString + '&searchWord=' + this.searchWord
      }

      this.$get(this.$SETTING_PARTNER + '?page=' + _page + '&perPage=' + this.size + queryString, this.$options.name + '_getList', (result) => {
        this.items = result.data.data
        this.totalCount = result.data.total

        this.page = _page

        /* 페이지네이션 */
        try {
          this.$refs.Pagination.init(this.$options.name, null, {
            searchWord: this.searchWord
          }, this.page, this.totalCount, Number(this.size))

        } catch (e) {
          console.log(e)
        }

      }, (result) => {
        this.httpError(result)
      })
    },
    goRegister() {
      /*등록*/
      this.addName = ''
      this.addImg = null
      this.addImgName = ''

      window.$('#partnerAddModal').modal('show');
    },
    register() {
      if (this.addName === '') {
        this.notify('error', '거래처 이름을 입력해주세요.')
        return
      }

      if (this.addImg === null) {
        this.notify('error', '거래처 이미지를 선택해주세요.')
        return
      }

      let formData = new FormData()
      formData.append('companyName', this.addName)
      formData.append('companyImg', this.addImg)

      this.$post(this.$SETTING_PARTNER_REGISTER, this.$options.name + '_register', formData, () => {
        this.notify('success', '성공적으로 등록되었습니다.')
        window.$('#partnerAddModal').modal('hide');
        this.getList(1)

      }, (result) => {
        this.httpError(result)
      })
    },
    upload(e, _type) {
      let files = e.target.files || e.dataTransfer.files
      if (!files.length) {
        return
      }

      if (_type === 0) { /*등록*/
        this.addImg = files[0]
        this.addImgName = files[0].name
        let url = URL.createObjectURL(files[0])
        this.addImgUrl = url

        this.$refs.addPartnerUploader.value = ''

      } else { /*수정*/
        this.editImg = files[0]
        this.editImgName = files[0].name
        let url = URL.createObjectURL(files[0])
        this.editImgUrl = url

        this.$refs.editPartnerUploader.value = ''
      }
    },
    goEdit(_item) {
      this.id = _item.id
      this.editName = _item.name
      this.editImgName = _item.path

      window.$('#partnerEditModal').modal('show');
    },
    edit() {
      if (this.editName === '') {
        this.notify('error', '거래처 이름을 입력해주세요.')
        return
      }

      let formData = new FormData()
      formData.append('companyName', this.editName)

      if (this.editImg !== null) {
        formData.append('companyImg', this.editImg)
      }

      this.$post(this.$SETTING_PARTNER_EDIT + this.id, this.$options.name + '_edit', formData, () => {
        this.notify('success', '성공적으로 변경되었습니다.')
        window.$('#partnerEditModal').modal('hide');
        this.getList(this.page)

      }, (result) => {
        this.httpError(result)
      })
    },
    goDelete(_item) {
      this.deleteItem = _item

      window.$('#partnerDeleteModal').modal('show');
    },
    del() {
      this.$delete(this.$SETTING_PARTNER_DELETE + this.deleteItem.id, this.$options.name + '_del', () => {
        this.notify('success', '성공적으로 삭제되었습니다.')
        window.$('#partnerDeleteModal').modal('hide');
        this.getList(this.page)

      }, (result) => {
        this.httpError(result)
      })
    }
  },
  watch: {
    '$route.query.page'() {
      if (this.$route.query.page) {
        this.getList(this.$route.query.page)
      } else {
        this.getList(1)
      }
    },
  },
}
</script>

<style scoped>

</style>