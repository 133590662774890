<template>
  <div>
    <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-4">
          <div class="col-sm-12">
            <h4 class="m-0">물류 배차 담당자 상세</h4>
            <span>물류 배차 담당자 정보를 관리합니다.</span>
          </div><!-- /.col -->
        </div><!-- /.row -->

        <div class="row sub-layout mt-0 mt-sm-3">
          <div class="container">
            <div class="row">
              <div class="col-6 board-write">
                <div class="input-group mb-2">
                  <div class="input-group-prepend w-100">
                    <span class="input-group-text">이름</span>
                    <input type="text" class="form-control" placeholder="*이름" v-model.trim="info.name">
                  </div>
                </div>
                <div class="input-group mb-2">
                  <div class="input-group-prepend w-100">
                    <span class="input-group-text">직책</span>
                    <input type="text" class="form-control" placeholder="*직책" v-model.trim="info.position"/>
                  </div>
                </div>
                <div class="input-group mb-2">
                  <div class="input-group-prepend w-100">
                    <span class="input-group-text">휴대폰번호</span>
                    <input type="text" class="form-control" placeholder="*휴대폰번호" v-model.trim="info.phone"/>
                  </div>
                </div>
                <div class="input-group mb-2">
                  <div class="input-group-prepend w-100">
                    <span class="input-group-text">사무실번호</span>
                    <input type="text" class="form-control" placeholder="사무실번호" v-model.trim="info.tel"/>
                  </div>
                </div>
                <div class="input-group mb-2">
                  <div class="input-group-prepend">
                    <span class="input-group-text">썸네일 이미지</span>
                  </div>
                  <div class="custom-file">
                    <input ref="thumbnailUploader" id="thumbNailFile" type="file" class="custom-file-input" accept="image/*"
                           @change="upload($event, 0)"/>
                    <label class="custom-file-label" for="thumbNailFile">{{ thumbNailName }}</label>
                  </div>
                </div>
                <div class="col-4" v-if="thumbNailUrl !== ''">
                  <img :src="thumbNailUrl" alt="프로필 이미지" class="img-thumbnail">
                </div>
                <div class="col-4" v-else-if="info.thumbnail_img">
                  <img :src="$IMAGE_DOMAIN + info.thumbnail_img" alt="프로필 이미지" class="img-thumbnail">
                </div>
              </div>
              <div class="col-6 board-write">
                <div class="input-group mb-2">
                  <div class="input-group-prepend">
                    <span class="input-group-text">상세 이미지</span>
                  </div>
                  <div class="custom-file">
                    <input ref="detailImgUploader" id="detailImgFile" type="file" class="custom-file-input" accept="image/*"
                           @change="upload($event, 1)" multiple/>
                    <label class="custom-file-label" for="detailImgFile" style="color: #999;">최대 5개까지 등록 가능</label>
                  </div>
                </div>
                <div class="row" v-if="detailImg.length > 0 || addDetailImgList.length > 0">
                  <div class="col-4 text-center" v-for="(detail, index) in detailImg" :key="'d' + index">
                    <img :src="$IMAGE_DOMAIN + detail.path" alt="상세 이미지" class="img-thumbnail">
                    <div class="row d-flex img-name-box">
                      <p class="pt-1 text-truncate mr-auto">{{ detail.path }}</p>
                      <button type="button" class="close btn-close ml-auto" aria-label="Close"
                              @click="deleteDetailImg(index)">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  </div>
                  <div class="col-4 text-center" v-for="(add, index) in addDetailImgList" :key="'a' + index">
                    <img :src="add.url" alt="상세 이미지" class="img-thumbnail">
                    <div class="row d-flex img-name-box">
                      <p class="pt-1 text-truncate mr-auto">{{
                          add.name
                        }}</p>
                      <button type="button" class="close btn-close ml-auto" aria-label="Close"
                              @click="deleteAddImg(index)">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-flex mb-3 mt-3 position-absolute" style="bottom:50px;left:calc(50% - 27px + 90px);">
                <input type="button" class="btn btn-secondary ml-auto mr-2" value="취소" @click="$router.back()"/>
                <input type="button" class="btn btn-success mr-auto" value="저장" @click="edit()"/>
              </div>
            </div>
          </div>
        </div>

      </div><!-- /.container-fluid -->
    </div>
  </div>
</template>

<script>
export default {
  name: "ManagerDetail",
  path: '/setting/manager/detail',
  data() {
    return {
      info: {},
      thumbNailFile: null,
      thumbNailUrl: '',
      thumbNailName: '',
      detailImg: [],
      addDetailImgList: [],
      deleteImgId: '',
    }
  },
  created() {
    this.emitter.emit(this.$EVENT_LNB_CHANGE, 'setting', 'manager')
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      this.$get(this.$SETTING_MANAGER_DETAIL + this.$route.query.id, this.$options.name + '_getData', (result) => {
        this.info = result.data

        /*썸네일*/
        let thumbnail = this.info.thumbnail_img.split('/')
        this.thumbNailName = thumbnail[thumbnail.length - 1]

        this.detailImg = this.info.detail_img

      }, (result) => {
        this.httpError(result)
      })
    },
    upload(e, _type) {
      let files = e.target.files || e.dataTransfer.files
      if (!files.length) {
        return
      }

      if (_type === 0) { /*썸네일*/
        this.thumbNailFile = files[0]
        this.thumbNailName = files[0].name
        let url = URL.createObjectURL(files[0])
        this.thumbNailUrl = url

        this.$refs.thumbnailUploader.value = ''

      } else if (_type === 1) { /*상세 이미지*/

        if (this.detailImg.length + this.addDetailImgList.length + files.length > 5) {
          this.notify('error', '상세이미지는 5장까지 등록 가능합니다')
          return
        }

        for (let i = 0; i < files.length; i++) {
          let url = URL.createObjectURL(files[i])
          this.addDetailImgList.push({'file': files[i], 'name': files[i].name, 'url': url})
        }

        this.$refs.detailImgUploader.value = ''
      }
    },
    deleteDetailImg(_idx) {
      /*삭제 이미지*/
      if (this.deleteImgId === '') {
        this.deleteImgId = this.detailImg[_idx].id
      } else {
        this.deleteImgId = this.deleteImgId + ',' + this.detailImg[_idx].id
      }

      this.detailImg.splice(_idx, 1)
    },
    deleteAddImg(_idx) {
      this.addDetailImgList.splice(_idx, 1)
    },
    edit() {
      if (this.info.name === '') {
        this.notify('error', '이름을 입력해주세요.')
        return
      }

      if (this.info.position === '') {
        this.notify('error', '직책을 입력해주세요.')
        return
      }

      if (this.info.phone === '') {
        this.notify('error', '휴대폰번호를 입력해주세요.')
        return
      }

      let formData = new FormData()
      formData.append('name', this.info.name)
      formData.append('position', this.info.position)
      formData.append('phone', this.info.phone)
      formData.append('tel', this.info.tel)

      if (this.thumbNailFile !== null) {
        formData.append('thumbnailImg', this.thumbNailFile)
      }

      if (this.addDetailImgList.length > 0) {
        for (let i = 0; i < this.addDetailImgList.length; i++) {
          formData.append('detailImg[]', this.addDetailImgList[i].file)
        }
      }

      if (this.deleteImgId !== '') {
        formData.append('delImgId', this.deleteImgId)
      }

      this.$post(this.$SETTING_MANAGER_EDIT + this.$route.query.id, this.$options.name + '_edit', formData, () => {
        this.notify('success', '성공적으로 변경되었습니다.')

        this.thumbNailFile = null
        this.thumbNailUrl = ''
        this.thumbNailName = ''
        this.detailImg = []
        this.addDetailImgList = []
        this.deleteImgId = ''

        this.getData()

      }, (result) => {
        this.httpError(result)
      })
    },
  },
}
</script>

<style scoped>

</style>