<template>
  <div class="login-page">
    <div class="login-box">
      <div class="login-logo">
        <a><b>마국로지스 ADMIN</b></a>
      </div>
      <!-- /.login-logo -->
      <div class="card">
        <div class="card-header">
        </div>
        <div class="card-body login-card-body">
          <p class="login-box-msg">관리자 로그인</p>
          <div class="input-group mb-3">
            <input type="email" class="form-control" placeholder="Email" v-model.trim="email">
            <div class="input-group-append">
              <div class="input-group-text">
                <span class="fas fa-id-badge"></span>
              </div>
            </div>
          </div>
          <div class="input-group mb-3">
            <input type="password" class="form-control" placeholder="Password" v-model.trim="password"
                   @keyup.enter="login()">
            <div class="input-group-append">
              <div class="input-group-text">
                <span class="fas fa-lock"></span>
              </div>
            </div>
          </div>
        </div>
        <!-- /.login-card-body -->
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Login',
  path: '/',
  meta: {
    hideLNBHeader: true
  },
  components: {},
  data() {
    return {
      email: '',
      password: '',
    }
  },
  created() {
    sessionStorage.removeItem('token')
  },
  methods: {
    login() {
      if (this.email === '') {
        this.notify('error', '이메일을 입력해주세요.')
        return
      }
      if (this.password === '') {
        this.notify('error', '비밀번호를 입력해주세요.')
        return
      }

      this.$post(this.$AUTH + '?email=' + this.email + '&password=' + encodeURIComponent(this.password), this.$options.name + '_login', null, (result) => {
        sessionStorage.setItem('token', result.data.access_token)

        this.$router.replace({name: 'Main'})

      }, (result) => {
        this.httpError(result)
      })
    }
  }
}
</script>

<style>
.dark-mode .login-page {
  background-color: #454d55 !important;
}
</style>
