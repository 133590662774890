<template>
  <!--  <nav class="main-header navbar navbar-expand navbar-dark">-->
  <nav class="main-header navbar navbar-expand " :class="{'navbar-dark' : isDarkMode, 'navbar-white navbar-light' : !isDarkMode}">

    <!-- Right navbar links -->
    <ul class="navbar-nav ml-auto">

      <li @click=logout() class="nav-item d-none d-sm-inline-block nav-link text-orange" style="cursor: pointer">
        <p><i class="fas fa-sign-out-alt"></i> 로그아웃</p>
      </li>
      <li class="nav-item d-none d-sm-inline-block nav-link">
        <!--        <div class="d-flex"><i class="fas fa-sun"></i>-->
        <!--        <div class="custom-control custom-switch">-->
        <!--          <input type="checkbox" class="custom-control-input" id="customSwitch1">-->
        <!--          <label class="custom-control-label" for="customSwitch1"></label>-->
        <!--        </div>-->
        <!--        <i class="fas fa-moon"></i></div>-->
        <p class="custom-control custom-switch">
          <i class="fas fa-sun mr-5"></i>
          <input type="checkbox" class="custom-control-input" id="customSwitch1" @change="changeMode()" v-model="isDarkMode">
          <label class="custom-control-label" for="customSwitch1"></label>
          <i class="fas fa-moon"></i>
        </p>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  props: ['requestPoint'],
  data() {
    return {
      // requestWithdraw: 11,
      // requestPointWithdraw: 5,
      // inquiry: 211,

      data: [],
      isDarkMode: false,
    }
  },
  components: {
  },
  created() {
    if (localStorage.getItem('isDarkMode') === '1') {
      this.isDarkMode = true
    } else {
      this.isDarkMode = false
    }
  },
  methods: {
    logout() {
      this.$router.push({ name: 'Login' })
    },
    changeMode() {
      if (localStorage.getItem('isDarkMode') === '1') {
        localStorage.setItem('isDarkMode', '0')
        this.isDarkMode = false
        this.emitter.emit(this.$EVENT_MODE_CHANGE)
      } else {
        localStorage.setItem('isDarkMode', '1')
        this.isDarkMode = true
        this.emitter.emit(this.$EVENT_MODE_CHANGE)
      }
    }
  }
}
</script>

<style>
</style>
