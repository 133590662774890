import axios from 'axios'

let requestOption = () => {
    return {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            'Accept-Language': localStorage.getItem('lang') ? localStorage.getItem('lang') : 'ko'
        },
        timeout: 60000
    }
}
let requestOptionWithToken = () => {
    return {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
            'Accept-Language': localStorage.getItem('lang') ? localStorage.getItem('lang') : 'ko'
        },
        timeout: 60000
    }
}
let requestOptionWithFile = () => {
    return {
        headers: {
            'Content-Type': 'multipart/form-data;',
            'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
            'Accept-Language': localStorage.getItem('lang') ? localStorage.getItem('lang') : 'ko'
        },
        timeout: 60000
    }
}

let pending_get = {}
let pending_post = {}
let pending_put = {}
let pending_delete = {}

export default Object.freeze  ({
    install(Vue) {
        Vue.config.globalProperties.$DOMAIN = process.env.VUE_APP_DOMAIN + "/admin"
        Vue.config.globalProperties.$IMAGE_DOMAIN = process.env.VUE_APP_IMAGE_DOMAIN /*이미지 main domain*/

        Vue.config.globalProperties.$AUTH = "/auth" /*인증-로그인*/

        Vue.config.globalProperties.$SUPPORT_QNA = "/support/qna" /*고객센터-qna*/
        Vue.config.globalProperties.$SUPPORT_QNA_DETAIL = "/support/qna/" /*고객센터-qna 상세*/
        Vue.config.globalProperties.$SUPPORT_QNA_REGISTER = "/support/qna" /*고객센터-qna 답변 등록*/
        Vue.config.globalProperties.$SUPPORT_QNA_EDIT = "/support/qna/" /*고객센터-qna 답변 수정*/

        Vue.config.globalProperties.$SETTING_PARTNER = "/setting/partner" /*주요 거래처 리스트*/
        Vue.config.globalProperties.$SETTING_PARTNER_REGISTER = "/setting/partner" /*주요 거래처 등록*/
        Vue.config.globalProperties.$SETTING_PARTNER_EDIT = "/setting/partner/" /*주요 거래처 수정 : id*/
        Vue.config.globalProperties.$SETTING_PARTNER_DELETE = "/setting/partner/" /*주요 거래처 삭제 : id*/

        Vue.config.globalProperties.$SETTING_MANAGER = "/setting/manager" /*물류 배차 담당자 리스트*/
        Vue.config.globalProperties.$SETTING_MANAGER_DETAIL = "/setting/manager/" /*물류 배차 담당자 상세*/
        Vue.config.globalProperties.$SETTING_MANAGER_REGISTER = "/setting/manager" /*물류 배차 담당자 등록*/
        Vue.config.globalProperties.$SETTING_MANAGER_EDIT = "/setting/manager/" /*물류 배차 담당자 수정 : id*/
        Vue.config.globalProperties.$SETTING_MANAGER_DELETE = "/setting/manager/" /*물류 배차 담당자 삭제 : id*/

        Vue.config.globalProperties.$SETTING_SITEINFO = "/setting/siteInfo" /*사이트 정보 관리*/
        Vue.config.globalProperties.$SETTING_SITEINFO_EDIT = "/setting/siteInfo" /*사이트 정보 관리 수정*/

        Vue.config.globalProperties.$post = function (callUrl, caller, postData, success, fail) {
            if (pending_post[arguments[0] + caller]) {
                console.log('request post fail : ' + arguments[0] + caller)
                return
            }
            pending_post[arguments[0] + caller] = true
            let _requestOption = requestOption()
            let token = sessionStorage.getItem('token')
            if(token && token.length > 0) {
                if (callUrl.includes(this.$SETTING_SITEINFO_EDIT) || callUrl.includes(this.$SETTING_MANAGER_REGISTER) || callUrl.includes(this.$SETTING_MANAGER_EDIT) ||
                    callUrl.includes(this.$SETTING_PARTNER_REGISTER) || callUrl.includes(this.$SETTING_PARTNER_EDIT)){
                    _requestOption = requestOptionWithFile()
                } else {
                    _requestOption = requestOptionWithToken()
                }
            }

            axios.post(this.$DOMAIN + callUrl, postData, _requestOption).then((response) => {
                pending_post[arguments[0] + caller] = false
                if (response.status === 200 || response.status === 201 || response.status === 205){
                    success(response.data)
                } else {
                    fail(response.data)
                }
            }).catch((e) => {
                pending_post[arguments[0] + caller] = false
                fail(e.response)
            })
        }

        Vue.config.globalProperties.$put = function (callUrl, caller, postData, success, fail) {
            if (pending_put[arguments[0] + caller]) {
                console.log('request put fail : ' + arguments[0] + caller)
                return
            }
            pending_put[arguments[0] + caller] = true
            let _requestOption = requestOption()
            let token = sessionStorage.getItem('token')
            if(token && token.length > 0) {
                _requestOption = requestOptionWithToken()
            }

            axios.put(this.$DOMAIN + callUrl, postData, _requestOption).then((response) => {
                pending_put[arguments[0] + caller] = false

                if (response.status === 205){
                    success(response.data)
                } else {
                    fail(response.data)
                }

            }).catch((e) => {
                pending_put[arguments[0] + caller] = false
                fail(e.response)
            })
        }

        Vue.config.globalProperties.$delete = function (callUrl, caller, success, fail) {
            if (pending_delete[arguments[0] + caller]) {
                console.log('request delete fail : ' + arguments[0] + caller)
                return
            }
            pending_delete[arguments[0] + caller] = true
            let _requestOption = requestOption()
            let token = sessionStorage.getItem('token')
            if(token && token.length > 0) {
                _requestOption = requestOptionWithToken()
            }

            axios.delete(this.$DOMAIN + callUrl, _requestOption).then((response) => {
                pending_delete[arguments[0] + caller] = false

                if (response.status === 204){
                    success(response.data)
                } else {
                    fail(response.data)
                }

            }).catch((e) => {
                pending_delete[arguments[0] + caller] = false
                fail(e.response)
            })
        }

        Vue.config.globalProperties.$get = function (callUrl, caller, success, fail) {
            if (pending_get[arguments[0] + caller]) {
                console.log('request get fail : ' + arguments[0] + caller)
                return
            }
            pending_get[arguments[0] + caller] = true
            let _requestOption = requestOption()
            let token = sessionStorage.getItem('token')
            if(token && token.length > 0) {
                _requestOption = requestOptionWithToken()
            }

            axios.get(this.$DOMAIN + callUrl, _requestOption).then((response) => {
                pending_get[arguments[0] + caller] = false

                if (response.status === 200){
                    success(response.data)
                } else {
                    fail(response.data)
                }

            }).catch(e => {
                pending_get[arguments[0] + caller] = false
                fail(e.response)
            })
        }
    }
})
